<template>
    <div class="py-6">
        <div class="fixed inset-0">
            <div class="absolute inset-0 bg-current-theme">
                <img
                    src="./../../assets/img/bg-2021.png"
                    class="mix-blend-luminosity w-full h-full object-cover">
            </div>
            <div class="absolute inset-0 bg-black opacity-60"></div>
        </div>

        <div class="relative text-white" :class="$store.getters.withFooter">
            <div class="hero flex flex-col items-center justify-center space-y-4">
                <h2 class="text-center">
                        <span
                            style="font-size: 80%;">Rockol è lieta di invitarti alla <strong>serata di premiazione</strong> dei</span><br>
                    <strong class="text-5xl sm:text-6xl">Rockol Awards {{ awardsYear }}</strong>
                </h2>

                <div class="body-font text-center space-y-2">
                    <div class="font-bold text-xl text-indigo-300">
                        {{ liveEventDate }}
                    </div>
                    <div class="text-xs opacity-50">presso</div>
                    <div class="font-semibold">Santeria Social Club, Viale Toscana 31, Milano</div>
                </div>

            </div>

            <div class="p-8 mx-auto max-w-xl mt-8 relative rounded border border-indigo-600 overflow-hidden shadow">
                <div class="absolute bg-indigo-700 inset-0 z-0 opacity-60"></div>
                <div class="relative text-center space-y-4">
                    <div class="leading-tight">
                        Inserisci l'indirizzo e-mail sul quale hai ricevuto la comunicazione e premi il pulsante
                        <strong>"Parteciperò"</strong>
                    </div>

                    <div class="font-semibold"
                         :class="formStatus.success == true ? 'text-2xl success' : 'text-xs error'">
                        {{ formStatus.message }}
                    </div>

                    <div v-if="checkInProgress" class="flex items-center justify-center">
                        <svg-icon icon="loading" width="140" height="140" cssClass="white-icon"></svg-icon>
                    </div>

                    <form @submit.prevent="rsvp()" v-if="showForm">
                        <input type="email"
                               v-model="rsvpEmailAddress"
                               class="rounded text-indigo-700"
                               placeholder="Inserisci l'indirizzo e-mail"
                               required>
                        <p>&nbsp;</p>
                        <button type="submit"
                                class="bg-green-500 px-6 py-3 rounded hover:bg-green-700 transition duration-300">
                            Parteciperò
                        </button>
                    </form>
                </div>
            </div>

            <div class="form text-center space-y-2 mt-8">

                <p class="mb-8">
                    <a href="https://www.rockol.it" class="font-semibold underline text-sm" target="_blank"
                       rel="noopener noreferrer">https://www.rockol.it</a>
                </p>

                <!--
                <div class="text-xs">Partner istituzionali:</div>

                <div class="flex flex-col sm:flex-row flex-wrap items-center justify-center mt-2 space-y-4 sm:space-x-8 pt-2">

                    <a href="https://www.ticketone.it/" target="_blank"
                       rel="noopener noreferrer" class="">
                        <img class="h-10" src="../../assets/img/tone_logo.png">
                    </a>

                    <a href="https://www.tiscali.it/" target="_blank" rel="noopener noreferrer"
                    ><img class="h-10" src="../../assets/img/logo-tiscali.png"></a>

                    <a href="https://www.siae.it/" target="_blank" rel="noopener noreferrer"
                    ><img class="w-20" src="../../assets/siae_logo.png"></a>
                </div>
                -->
            </div>
        </div>
    </div>

</template>

<script>
import SvgIcon from '../shared/SvgIcon.vue';

import axios from 'axios';
import {rsvpApiSettings} from '../../shared/settings';
import * as qs from "qs";
import format from "date-fns/format";

import itLocale from "date-fns/locale/it";

export default {
    data: function () {
        return {
            awardsYear: process.env.VUE_APP_AWARDS_YEAR,
            rsvpEmailAddress: '',
            checkInProgress: false,
            submitted: false,
            formStatus: {
                success: null,
                message: null,
            }
        }
    },
    components: {
        'svg-icon': SvgIcon
    },
    computed: {
        showForm() {
            return this.formStatus.success !== true && this.submitted === false && this.checkInProgress === false
        },
        liveEventDate() {
            const liveEventDate = new Date(this.$store.state.app.liveEventDate);

            const day = format(
                liveEventDate,
                'dddd',
                {locale: itLocale}
            );
            const dayString = day.charAt(0).toUpperCase() + day.slice(1);

            return [
                dayString,
                format(
                    liveEventDate,
                    'D MMMM YYYY',
                    {locale: itLocale}
                ),
                'alle ore',
                format(
                    liveEventDate,
                    'HH:mm'
                )
            ].join(' ');
        }
    },
    methods: {
        rsvp() {
            // Check indirizzo e-mail
            const vm = this;
            vm.checkInProgress = true;
            vm.formStatus.message = null;

            const separator = rsvpApiSettings.basePath.indexOf('?') !== -1 ? "&" : "?";
            const url = rsvpApiSettings.host + rsvpApiSettings.basePath
                + separator
                + "requestType=rsvp"
                + "&output=json";

            axios({
                method: 'post',
                url,
                headers: rsvpApiSettings.defaultHeaders,
                data: qs.stringify({
                    email: this.rsvpEmailAddress,
                })
            }).then(function (res) {
                vm.checkInProgress = false;
                console.log(res);
                if (res.data.success !== null) {
                    vm.formStatus.success = res.data.success;
                    vm.formStatus.message = res.data.message;
                }
            })
        }
    },
}
</script>

<style scoped>
.success {
    color: #50c250;
}

.error {
    color: #ff8300;
}
</style>


